import { qspost } from '@/config/axios';

//获取列表
export const getList = data => qspost("request?", data, "adList");

//新增
export const add = data => qspost("request?", data, "addAdList");

//编辑
export const edit = data => qspost("request?", data, "updateAdList");

//删除
export const del = data => qspost("request?", data, "deleteAdList");

//冻结*解冻
export const freezeThaw = data => qspost("request?", data, "lockAdList");