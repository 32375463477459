<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        广告位置: <el-select v-model="search_box.type">
        <el-option :value="0" label="全部"></el-option>
        <el-option v-for="item of types" :key="item" :value="item.id" :label="item.label"></el-option>
      </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true;title = '添加广告'">添加广告</el-button>
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pic" label="广告图标" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <!--          <div class="big" @click="amplification(scope.row.pic)">{{scope.row.pic}}</div>-->
          <ximg :src="scope.row.pic" :width="30" :height="30" @click="amplification(scope.row.pic)" alt=""></ximg>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="广告位" width="120" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{getAdLabel(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="广告标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="link" label="广告地址" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.enable == '启用' ? '#33C933' : '#F33333' }">{{scope.row.enable}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="freezeThaw(scope.row,scope.row.enable == '启用' ? '禁用' : '启用')">{{scope.row.enable == '启用' ? '禁用' : '启用'}}</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
    <!-- 添加修改广告弹窗 -->
    <el-dialog :title="title" v-model="dialogAdd" width="600px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <el-row>
        <el-col :span="4" align="right">广告标题:</el-col>
        <el-col :span="20">
          <el-input v-model="dialogArr.title" placeholder="广告标题" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">广告位置:</el-col>
        <el-col :span="20">
          <el-select :disabled="title!='添加广告'" v-model="dialogArr.ad_type">
            <el-option v-for="item of types" :key="item" :value="item.id" :label="item.label"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">广告权重:</el-col>
        <el-col :span="20">
          <el-input v-model="dialogArr.weight" placeholder="广告权重" size="small" type="number"></el-input>
          <div  style="color: red">权重约稿展示几率越高</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">跳转方式:</el-col>
        <el-col :span="20">
          <el-radio v-model="dialogArr.type" checked :label=0 >外部链接</el-radio>
          <el-radio v-model="dialogArr.type" :label=1 >内部跳转</el-radio>
        </el-col>
      </el-row>
      <el-row v-if="dialogArr.type == 0">
        <el-col :span="4" align="right">广告地址:</el-col>
        <el-col :span="20">
          <el-input v-model="dialogArr.link" placeholder="广告地址" size="small"></el-input>
          <div class="noteRed">必须为http://或https://开头</div>
        </el-col>
      </el-row>
      <el-row v-if="dialogArr.type == 1">
        <el-col :span="4" align="right">跳转选择:</el-col>
        <el-col :span="20">
          <el-select v-model="dialogArr.link2">
            <el-option v-for="item of insideRouters" :key="item" :label="item.label" :value="item.value" ></el-option>
          </el-select>
          <el-input class="width_200" v-model="dialogArr.extra" placeholder="请输入额外参数!" ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">精选宣传图:</el-col>
        <el-col :span="20">
          <el-upload class="avatar-uploader"      style="width: 280px; float: left"                accept="image/*"
                     action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
            <ximg  :enlarge="false" :width="280"  v-if="dialogArr.imgUrl" :src="dialogArr.imgUrl" class="avatar" ></ximg>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="note">支持扩展名:.png .jpg .gif</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" align="center">
          <el-button type="primary" @click="dialogAdd=false">取消</el-button>
          <el-button type="primary" @click="add" v-if="title == '添加广告'">添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" align="center">
        </el-col>
      </el-row>
    </el-dialog>
    <div>
      <el-image class="bigimg" style="width: 0.01px; height: 0.01px" :src="src" :preview-src-list="srcList">
      </el-image>
    </div>
  </div>
</template>

<script>
import { getList, add, edit, del, freezeThaw } from "@/api/ad/slideshow";
import { upload } from "@/api/common/common";
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "adSlideshow",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      tableData: [{}], //数据
      row: [], //选中的数据
      title: "", //弹窗标题
      search_box:{
        type:0,
      },
      insideRouters:[
        { label:"关闭窗口" ,value:"#close" },
        { label:"会员充值" ,value:"#userPay" },
        { label:"钱包充值" ,value:"#walletPay" },
        { label:"游戏中心" ,value:"#gameCenter" },
        { label:"会员分享" ,value:"#share" },
        { label:"帖子详情" ,value:"#tagPost" },
        { label:"社区标签" ,value:"#tagCommunity" },
        { label:"漫画标签" ,value:"#tagCartoon" },
        { label:"美图标签" ,value:"#tagPicture" },
        { label:"小说标签" ,value:"#tagStory" },
        { label:"下载站" ,value:"#addlogsadver" },
      ],

      types:[
        {label:"娱乐轮播图",id:1,},
        {label:"启动页广告",id:2,},
        {label:"轮播图广告",id:3,},
        {label:"视频页广告",id:4,},
        {label:"弹窗广告",id:5,},
        {label:"右侧浮动广告",id:6,},
        {label:"视频暂停广告",id:7,},
        {label:"短视频广告",id:8,},
        {label:"信息流广告",id:9,},
        {label:"社区广告",id:10,},
        {label:"更多内嵌广告",id:11,},
      ],
      dialogAdd: false,
      dialogArr: {
        title: "", //标题
        weight: 0, //权重
        ad_type: 0, //广告位置
        type: 0, //跳转方式
        link: "", //地址
        link2: "", //第二个跳转地址
        url: "", //图标地址
        imgUrl: "", //图标完整地址
        extra: "", //额外参数
      },
      type: 4, // 类型1=广告列表,2=启动页,3=视频页,4=轮播图,5=弹窗,6浮动
      src: "",
      srcList: [], //放大图片
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getTypes()
    this.getList();
  },
  methods: {
    getTypes(){
      httpClient("getAdverTypes").post().then((res)=>{
        if (res.data){
          this.types = JSON.parse(res.data)
        }
      })
    },
    getAdLabel(row){
      for(const t of this.types){
        if (t.id == row.type){
          return t.label
        }
      }
      return "未知广告位:"+row.type
    },
    //获取广告
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "type", val: this.search_box.type },
      ])
          .then((res) => {
            if (res.code == 0) {
              this.pager.total = res.data.total;
              this.tableData = res.data.list;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    exceuteLink(){
      if (this.dialogArr.type == 0){
        return this.dialogArr.link
      }else{
        if (this.dialogArr.extra){
          return this.dialogArr.link2+"/" + this.dialogArr.extra
        }else{
          return this.dialogArr.link2
        }
      }
    },
    //新增广告
    add() {
      if (this.dialogArr.weight < 0)
        return this.$message({ message: "权重不能未负数", type: "error" });
      var link = this.exceuteLink()
      add([
        { key: "title", val: this.dialogArr.title },
        { key: "weight", val: this.dialogArr.weight },
        { key: "link", val: link },
        { key: "url", val: this.dialogArr.url },
        { key: "type", val: this.dialogArr.ad_type },
      ])
          .then((res) => {
            if (res.code == 0) {
              this.getList();
              this.$message({ message: res.data, type: "success" });
              this.dialogAdd = false;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    //把数据放入编辑内
    editor(row) {
      this.row = row;
      this.dialogArr = {
        title: row.title, //标题
        weight: row.weight, //权重
        link: row.link, //广告地址
        url: row.pic_, //图片地址
        imgUrl: row.pic, // 图片完整地址
        type:0,
        ad_type: row.type,
        link2: row.link,
        extra: "",
      };
      this.title = `编辑广告(ID：${row.id})`;
      if (row.link.indexOf("#") == 0){
        this.dialogArr.type = 1
        // 额外参数解析
        var index = row.link.indexOf("/")
        if ( index > 0){
          this.dialogArr.link2 = row.link.substr(0,index)
          this.dialogArr.extra = row.link.substr(index+1)
        }
      }
      this.dialogAdd = true;
    },
    //编辑专题
    edit() {
      if (this.dialogArr.weight < 0)
        return this.$message({ message: "权重不能未负数", type: "error" });
      var link = this.exceuteLink()
      edit([
        { key: "id", val: this.row.id },
        { key: "title", val: this.dialogArr.title },
        { key: "weight", val: this.dialogArr.weight },
        { key: "link", val: link },
        { key: "url", val: this.dialogArr.url },
        { key: "type", val: this.dialogArr.ad_type },
      ])
          .then((res) => {
            if (res.code == 0) {
              this.getList();
              this.$message({ message: res.data, type: "success" });
              this.dialogAdd = false;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    //删除专题
    del(row) {
      this.row = row;
      this.$confirm(
          "你确定要删除广告：" + row.title + "吗？此操作无法撤回,请谨慎操作",
          "删除广告",
          { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
          .then(() => {
            del([
              { key: "id", val: this.row.id },
              { key: "type", val: this.type },
            ])
                .then((res) => {
                  if (res.code == 0) {
                    this.getList();
                    this.$message({ message: res.data, type: "success" });
                  } else {
                    this.$message({ message: res.msg, type: "error" });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    //冻结*解冻
    freezeThaw(row, str) {
      this.row = row;
      this.$confirm(
          "你确定要" + str + "广告：" + row.title + "吗？",
          "提示",
          { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
          .then(() => {
            freezeThaw([
              { key: "id", val: this.row.id },
              { key: "type", val: this.type },
            ])
                .then((res) => {
                  if (res.code == 0) {
                    this.getList();
                    this.$message({ message: res.data, type: "success" });
                  } else {
                    this.$message({ message: res.msg, type: "error" });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    //关闭弹窗清空数据
    closeDialog() {
      this.dialogArr = {
        title: "", //标题
        weight: 0, //权重
        link: "", //简介
        url: "", //图片地址
        imgUrl: "", //图片完整地址
      };
      this.row = []; //清空选中数据
    },
    //上传图片
    upload(param) {
      this.$message({ message: "上传中请稍后", type: "success" });
      upload({ file: param.file, name: "ad",isencode:"true" })
          .then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogArr.url = res.data.filename;
              this.dialogArr.imgUrl = res.data.path;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // 图片上传前校验拦截
    beforeAvatarUpload(file) {
      const type = file.type;
      const isType = type !== "image/jpeg" ? type !== "image/png" ? type !== "image/gif" ? false : true : true : true;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传图片格式错误!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过2MB!");
      }
      return isType && isLt2M;
    },
    //放大图片功能
    amplification(url) {
      this.src = url;
      this.srcList = [];
      this.srcList.push(url);
      setTimeout(() => {
        document.getElementsByClassName("bigimg")[0].childNodes[0].click();
      }, 50);
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
/* 图片上传部分 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.note {
  clear:both;
}
.noteRed {
  color: red;
}
.big {
  cursor: pointer;
}
.el-row{margin-bottom: 10px;}
</style>