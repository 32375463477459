import {post, postHeader, postVideo, qspost} from '@/config/axios';

//上传图片
export const upload = data =>post("/uploadFile",data);
export const uploadHeader = (data,header,progress,timeout) =>postHeader("/uploadFile",data,header,progress,timeout);

//上传视频 以及大型文件， 用到进度条的
export const uploadVideo = (data,progress,timeout) =>postVideo("/uploadFile",data,progress,timeout);

//每隔10s访问一次接口
export const refresh = data => qspost("request?",data,"updateUptime");