import axios from "axios";
import {ElMessage} from "element-plus";
import Qs from "qs";
import common from "@/common/common"
import CryptoJS from "crypto-js";
function empty(object){
    if (typeof object == "object"){
        for(var key in object){
            return false
        }
        return true
    }else{
        return object.length === 0
    }
}

let ClickMap = {}
function checkisClick(key){
    if(ClickMap[key] === undefined) {
        setClick(key)
        return false
    }
    return true
}

function setClick(key){
    ClickMap[key] = true
}

function deleteClickKey(key){
    delete ClickMap[key]
}

function httpClient(ac){
    var host = "";
    return {
        // http请求对象
        headers: {}, // 请求头部
        timeout :60, // 默认超时时间
        params : {}, // 请求参数
        url : host+"/request", // 请求地址
        upload_url:"",
        ac  :  ac, // 请求参数
        isUpload : true, // 是否upload服务器上传上传请求
        contentType : "application/x-www-form-urlencoded",// 默认的contentType
        progress : null,
        per_slice: 1024 * 1024 * 2,
        setUploadUrl: function(url){
            if (url == ""){
                url = this.host;
            }
            this.upload_url = url
            this.isUpload = false
            return this
        },
        setPerSlice : function (size){
            this.per_slice = size
            return this
        },
        setHeader : function (_headers) {
            for(var key in _headers){
                this.headers[key] = _headers[key]
            }
            return this
        },
        setTimeout : function (timeout) {
            this.timeout = timeout
            return this
        },
        setParams : function (params) {
            this.params = params
            return this
        },
        post :function (params) {
            if (params !== undefined){
                this.params = params
            }
            this.params['token'] = localStorage.getItem("token")
            this.params['uid'] = localStorage.getItem("uid")
            this.params['ac'] = this.ac
            if (this.timeout > 0){
                axios.defaults.timeout = 1000 * this.timeout;
            }
            var _params = Qs.stringify(this.params) // 拦截相同参数的请求
            var key = this.ac + "/" + _params
            if (checkisClick(key)){
                // return
            }
            axios.defaults.headers['Content-Type'] = this.contentType;
            return new Promise((resolve, reject) => {
                axios.post(this.url+"/"+this.ac,_params).then(res => {
                    deleteClickKey(key)
                    if (res.data.code == 40000) {
                        common.hideLoading()
                        localStorage.removeItem("token");
                        localStorage.removeItem("nickname");
                        localStorage.removeItem("current_router")
                        setTimeout(()=>{
                            location = '/#/login';
                        },200)
                        return
                    }else{
                        resolve(res.data);
                    }
                }).catch(err => {
                    deleteClickKey(key)
                    reject(err.toString())
                    ElMessage({ message: '加载失败:'+err.toString(), type: 'error' });
                })
            });
        },
        setProgress : function (progress){
            this.progress = progress
            return this
        },
        setAction : function(ac){
            this.ac = ac
            return this
        },

        upload : function(file){
            if (this.timeout > 0){
                axios.defaults.timeout = 1000 * this.timeout;
            }
            let data = new FormData();
            data.append('uploadfile',file)
            let upload_url = "";
            if (this.isUpload){
                upload_url = localStorage.getItem("uploader_api"); // 视频上传地址
            }
            let headers = {
                "Content-Type":'multipart/form-data;charset-utf-8',
                "Token":localStorage.getItem("token"),
                "Uid":localStorage.getItem("uid"),
            }
            if(!empty(this.headers)){
                for(var key in this.headers){
                    headers[key] = this.headers[key]
                }
            }
            if (this.upload_url !== undefined && this.upload_url != ""){
                upload_url = this.upload_url
            }
            return new Promise((resolve, reject) => {
                axios.post(upload_url+"/uploadFile", data,{
                    headers:headers,
                    onUploadProgress:(event)=>{
                        if (this.progress !== undefined && typeof this.progress === "function"){
                            this.progress(event)
                        }
                    },
                }).then(res => {
                    if (res.data.code == 40000) {
                        common.hideLoading()
                        localStorage.removeItem("token");
                        localStorage.removeItem("nickname");
                        localStorage.removeItem("current_router")
                        setTimeout(()=>{
                            location = '/#/login';
                        },200)
                        return
                    }
                    resolve(res.data);
                }).catch(err => {
                    reject(err.toString())
                    ElMessage({ message: '加载失败:'+err.toString(), type: 'error' });
                })
            });
        },
        getFileHash(hasher,file,currentChunk,callback){
            var reader = new FileReader(), self = this;
            var blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice;
            var chunkSize = this.per_slice;
            var chunks = Math.ceil(file.size / chunkSize);
            var start = currentChunk * chunkSize;
            var end = start + chunkSize >= file.size ? file.size : start + chunkSize;
            reader.readAsArrayBuffer(blobSlice.call(file, start, end));
            reader.onload = function (evt) {
                var fileStr = evt.target.result;
                var tmpWordArray = self.arrayBufferToWordArray(fileStr);
                hasher.update(tmpWordArray);
                currentChunk += 1;
                fileStr = null;
                tmpWordArray = null;
                if (currentChunk < chunks) {
                    self.getFileHash(hasher,file,currentChunk,callback)
                }else{
                    callback({
                        hash:hasher.finalize().toString(),
                        file:file,
                        size:file.size,
                    })
                }
            }
        },
        arrayBufferToWordArray(ab) {
            var i8a = new Uint8Array(ab);
            var a = [];
            for (var i = 0; i < i8a.length; i += 4) {
                a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
            }
            return CryptoJS.lib.WordArray.create(a, i8a.length);
        },
        uploadSlices : function(file,hInfo,loading,suc,err,slice_start){
            // 分片上传，通过这里处理
            var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
            var self = this
            if (slice_start >= file.size){
                return
            }
            var end = slice_start + this.per_slice
            if(end >= file.size){
                end = file.size
            }
            var reader = new FileReader()
            var _file = blobSlice.call(file,slice_start,end)
            reader.readAsArrayBuffer(_file)
            reader.onload = function (e) {
                var hasher = CryptoJS.algo.SHA256.create()
                var fileStr = e.target.result;
                var tmpWordArray = self.arrayBufferToWordArray(fileStr);
                hasher.update(tmpWordArray);
                var slice_hash = hasher.finalize().toString()
                // 检测改碎片是否已经被上传过
                var isPass = false
                for(var i in hInfo.slice){
                    if (hInfo.slice[i].hash == slice_hash){
                        isPass = true
                        break
                    }
                }
                if(isPass){
                    loading(end) // 追加进去的
                    self.uploadSlices(file,hInfo,loading,suc,err,end)
                    return
                }
                self.headers['hash'] = hInfo.hash
                self.headers['video_hash'] = hInfo.hash
                self.headers['slicehash'] = slice_hash
                self.headers['slice_hash'] = slice_hash
                self.headers['ext'] =  hInfo.ext
                self.headers['video_ext'] =  hInfo.ext
                self.headers['size'] = hInfo.size
                self.headers['video_size'] = hInfo.size
                self.headers['isslice'] = "true"
                self.headers['slice_upload'] = "true"
                var last_loaded = 0
                self.setProgress(function (e){
                    loading(e.loaded-last_loaded) // 追加进去的
                    last_loaded = e.loaded
                }).upload(_file).then((res)=>{
                    if (res.code == 0){
                        if (res.data.done){
                            suc(res.data)
                        }else{
                            self.uploadSlices(file,hInfo,loading,suc,err,end)
                        }
                    }else{
                        err(res.msg)
                    }
                },(err_str)=>{
                    err(err_str)
                })
            }
        },
        uploadSlice : function(file,loading,suc,err){
            this.getFileHash(CryptoJS.algo.SHA256.create(),file,0,(fInfo)=>{
                var index = fInfo.file.name.lastIndexOf(".")
                if (index <= 0){
                    return
                }
                httpClient("getHashInfo").post({name:fInfo.file.name,hash:fInfo.hash,ext:fInfo.file.name.substr(index+1),size:fInfo.size}).then((res)=>{
                    if (res.data.filename != ""){
                        // 已经成功
                        loading(99)
                        suc({"hash":fInfo.hash,"done":true,"filename":res.data.filename})
                        return
                    }
                    var total_loaded = 0
                    this.setUploadUrl(host).uploadSlices(file,res.data.info,function (loaded) {
                        total_loaded += loaded
                        if (Number(total_loaded / file.size * 100) >= 99.99){
                            loading(99.99)
                        }else{
                            loading(Number(total_loaded / file.size * 100).toFixed(2))
                        }
                    },function (data){
                        suc({"hash":fInfo.hash,"done":true,"filename":data.filename})
                    },err,0)
                },(err)=>{
                    console.log(err)
                })
            })
        },
        setUrl(url){
            this.url = url
            return this
        },
        uploadVideoSlice(file,loading,suc,err){
            this.getFileHash(CryptoJS.algo.SHA256.create(),file,0,(fInfo)=>{
                var index = fInfo.file.name.lastIndexOf(".")
                if (index <= 0){
                    return
                }
                var url = localStorage.getItem("uploader_api"); // 视频上传地址
                httpClient("getHashInfo").setUrl(url+"/request").post({name:fInfo.file.name,hash:fInfo.hash,ext:fInfo.file.name.substr(index+1),size:fInfo.size}).then((res)=>{
                    if (res.data.filename != ""){
                        // 已经成功
                        loading(99)
                        suc({"hash":fInfo.hash,"done":true,"filename":res.data.filename})
                        return
                    }
                    var total_loaded = 0
                    this.uploadSlices(file,res.data.video,function (loaded) {
                        total_loaded += loaded
                        // console.log(file.size+"|"+total_loaded+"|"+loaded+"|"+(Number(total_loaded / file.size * 100).toFixed(2)))
                        if (Number(total_loaded / file.size * 100) >= 99.99){
                            loading(99.99)
                        }else{
                            loading(Number(total_loaded / file.size * 100).toFixed(2))
                        }
                    },function (data){
                        suc({"hash":fInfo.hash,"done":true,"filename":data.filename})
                    },err,0)
                },(err)=>{
                    console.log(err)
                })
            })
        }
    }
}
export default httpClient
